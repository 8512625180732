import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ScrollableList
} from 'sharedComponents';
import {
    IconDataLogger,
    IconSortDecending,
    IconWarningType
} from 'svgIcons/MotionPortalIcons';
import { InfoButtonWithOnlineHelp } from 'sharedComponents/InfoButtonWithOnlineHelp';
import { AssetOverviewDetails } from '../../AssetOverviewDeatils';
import { apiCallStatus } from 'helpers/constants';
import colors from 'theme/_colors.scss';
import './style.scss';


const ScrollableListComponent = ({
    noDataLabel,
    className,
    loadingStatus,
    hasMore,
    pageSize,
    loadMore,
    renderList,
    selectedAsset,
    hasData,
    selectedPowertrain,
    selectedAssetGroup,
    asset,
    onReset
}) => {
    const { t: translate } = useTranslation();
    const [leftWidth, setLeftWidth] = useState(100);
    const [leftWidthPixel, setLeftWidthPixel] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [showRightContainer, setShowRightContainer] = useState(false);
    const [scrollBarWidth, setScrollBarWidth] = useState(0);

    const resetState = useCallback(() => {
        setLeftWidth(100);
        setShowRightContainer(false);
        setLeftWidthPixel(0);
        if (onReset) {
            onReset();
        }
    }, [onReset]);

    useEffect(() => {
        if (loadingStatus === apiCallStatus.LOADING || !hasData?.length) {
            resetState();
            setLeftWidthPixel(0);
            setLeftWidth(100);
        } else if (selectedAsset?.assetId && loadingStatus !== apiCallStatus.LOADING) {
            setLeftWidth(70);
            setShowRightContainer(true);
        } else {
            resetState();
        }
    }, [loadingStatus, selectedAsset, hasData]);

    useEffect(() => {
        if (isDragging) {
            setScrollBarWidth(912);
        }
    }, [isDragging]);

    const handleMouseDown = useCallback(() => {
        setIsDragging(true);
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
    }, []);

    const handleMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                const containerRect = e.currentTarget.getBoundingClientRect();
                const newLeftWidth = (e.clientX - containerRect.left) / containerRect.width * 100;

                const constrainedLeftWidth = Math.min(Math.max(newLeftWidth, 50), 70);
                const constrainedLeftWidthPixel = constrainedLeftWidth / 100 * e.currentTarget.offsetWidth;
                setLeftWidthPixel(constrainedLeftWidthPixel);
                setLeftWidth(constrainedLeftWidth);
            }
        },
        [isDragging]
    );

    const renderInfoTooltip = (description) => {
        return <InfoButtonWithOnlineHelp infoText={description} />;
    };

    const renderHeader = () => {
        return <div className='asset-list-header'>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewNameColumnTitle')}
            </div>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewConditionIndices')}
                <div className='column-icon-right'>
                    <IconSortDecending color={colors.black} />
                </div>
            </div>
            <div className='header-item'>
                {translate('ABB.Powertrain.Frontend.limitsTitle')}
            </div>
            <div className='header-item'>
                <div className='column-icon'>
                    <IconWarningType color={colors.black} />
                </div>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewThresholdColumnTitle')}
                {renderInfoTooltip(translate('ABB.Powertrain.Frontend.thresholdColumnInfoText'))}
            </div>
            <div className='header-item'>
                <div className='column-icon'>
                    <IconDataLogger heigth='16px' width='16px' />
                </div>
                {translate('ABB.Powertrain.Frontend.conditionIndicesOverviewTrendColumnTitle')}
                {renderInfoTooltip(translate('ABB.Powertrain.Frontend.trendColumnInfoText'))}
            </div>
        </div>;
    };

    return (
        <div className='overview-list resizable-layout'
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}>
            <div className='left-container' style={{ width: `${leftWidth}%` }}>
                <div className={`overview-scrollable-list ${className} ${loadingStatus === apiCallStatus.LOADING ? 'overview-scrollable-list__render' : ''}`}>
                    <div className={`list-item ${selectedAsset?.assetId && leftWidthPixel < scrollBarWidth && 'enable-scroll'}`}>
                        {renderHeader()}
                        <ScrollableList
                            emptyLabel={noDataLabel}
                            withLoading
                            loadingStatus={loadingStatus}
                            hasMore={hasMore}
                            pageSize={pageSize}
                            loadMore={() => loadMore()}
                        >
                            {renderList()}
                        </ScrollableList>

                    </div>
                </div>
            </div>
            {
                showRightContainer &&
                <>
                    <div className='resize-handle' onMouseDown={handleMouseDown}>
                        <button className='resize-handle__button' onMouseDown={handleMouseDown}>
                            <IconAdjust />
                        </button>
                    </div>
                    <div className='right-container' style={{ width: `${100 - leftWidth}%` }}>
                        <div className={`asset-overview-details ${className}`}>
                            <AssetOverviewDetails
                                selectedAsset={selectedAsset}
                                data={hasData}
                                selectedPowertrain={selectedPowertrain}
                                selectedAssetGroup={selectedAssetGroup}
                                asset={asset}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

const IconAdjust = () => {
    return (
        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='10' cy='3.5' r='1' transform='rotate(90 10 3.5)' fill='#696969' />
            <circle cx='6' cy='3.5' r='1' transform='rotate(90 6 3.5)' fill='#696969' />
            <circle cx='10' cy='8.5' r='1' transform='rotate(90 10 8.5)' fill='#696969' />
            <circle cx='6' cy='8.5' r='1' transform='rotate(90 6 8.5)' fill='#696969' />
            <circle cx='10' cy='13.5' r='1' transform='rotate(90 10 13.5)' fill='#696969' />
            <circle cx='6' cy='13.5' r='1' transform='rotate(90 6 13.5)' fill='#696969' />
        </svg>
    );
};

ScrollableListComponent.propTypes = {
    noDataLabel: PropTypes.string,
    className: PropTypes.string,
    loadingStatus: PropTypes.string,
    hasMore: PropTypes.bool,
    pageSize: PropTypes.number,
    loadMore: PropTypes.func,
    renderList: PropTypes.func,
    selectedAsset: PropTypes.object
};

export default ScrollableListComponent;
